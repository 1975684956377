export default defineNuxtRouteMiddleware(async (route) => {
  if (import.meta.env.VITE_ENVIRONMENT === 'local')
    console.log('couponRequired.ts')

  const hasCouponParam = 'coupon' in route.query
  if (!hasCouponParam) {
    console.log('navigating here 1')
    return navigateTo('/')
  }
})
